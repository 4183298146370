import { Component } from "react"
import Navigation from "../Components/Navigation";

class TennisKata extends Component {
    render() {
        return (
            <div>
                <Navigation path="katas" />
                <div className="container">
                    <h1>Tennis Kata</h1>
                    <div className="description">
                        <img src="/img/katas/kata_tennis.png" alt="Tennis Kata" align="left" />
                        <p>
                            Tennis is a ball and racquet game that is scored in an interesting way. Instead of scoring the game using consecutive integers to denote number of points won by the players, it uses the scoring 0, 15, 30, 40, and A.
                        </p>
                        <p>
                            There are also special rules on how to score a game in tennis. A player must score at least four points in total and two more than their opponent to win. When the points are four, and the scores are equal, then the score is 'deuce' (40:40). When the points are four or more, and a player has one point more than their opponent, then the score is 'advantage' (A:40).
                        </p>
                        <p>
                            You can read more about <a href="https://en.wikipedia.org/wiki/Tennis" target="_blank" rel="noopener noreferrer">Tennis</a> on Wikipedia.
                        </p>
                    </div>
                    <hr />
                    <div className="features">
                        <div className="feature">
                            <h2>Feature 1 - Scoring a Game in Real Time</h2>
                            <p>
                                We want a program that can be used to score a game in real time, so we can use it for all of the tennis related endeavours we plan to undertake in the future. To begin with, we're going to need a way to update the score when a player wins a point, see what the current score is after each service, and see if their is a winner based on the current score and the rules above.
                            </p>
                            <div className="row">
                                <div className="card">
                                    <h3>Winning a Point Increases Score Correctly</h3>
                                    <div className="card-contents">
                                        <p>
                                            As a library user<br />
                                            I want the score to increase when a player wins a point<br />
                                            So that I can display the current score correctly
                                        </p>
                                        <ul>
                                            <li>
                                                Given the score is 0:0<br />
                                                When the server wins a point<br />
                                                Then the score is 15:0
                                            </li>
                                            <li>
                                                Given the score is 15:15<br />
                                                When the receiver wins a point<br />
                                                Then the score is 15:30
                                            </li>
                                            <li>
                                                Given the score is 30:30<br />
                                                When the server wins a point<br />
                                                Then the score is 40:30
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                                <div className="card">
                                    <h3>Deuce and Advantage are Scored Correctly</h3>
                                    <div className="card-contents">
                                        <p>
                                            As a library user<br />
                                            I want deuce and advantage to be scored correctly<br />
                                            So that I can display the score correctly
                                        </p>
                                        <ul>
                                            <li>
                                                Given the score is 40:40<br />
                                                When the receiver wins a point<br />
                                                Then the score should be 40:A
                                            </li>
                                            <li>
                                                Given the score is A:40<br />
                                                When the receiver wins a point<br />
                                                Then the score should be 40:40
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                            <div className="row">
                                <div className="card">
                                    <h3>Winning Points are Scored Correctly</h3>
                                    <div className="card-contents">
                                        <p>
                                            As a library user<br />
                                            I want the winning point to be scored correctly<br />
                                            So that I can display the winner
                                        </p>
                                        <ul>
                                            <li>
                                                Given the score is 40:30<br />
                                                When the server wins a point<br />
                                                Then the server should win
                                            </li>
                                            <li>
                                                Given the score is 40:A<br />
                                                When the receiver wins a point<br />
                                                Then the receiver should win
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="row katas" />
            </div>
        );
    }
}

export default TennisKata;
