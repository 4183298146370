import { createRoot } from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';

import ReactGA from 'react-ga4';
ReactGA.initialize('G-Y9T6WEDEMB');
const domNode = document.getElementById('root');
const root = createRoot(domNode);
root.render(<App />);
reportWebVitals();
