import Navigation from "../Components/Navigation";

function Privacy() {
    return (
        <div>
            <Navigation path="about" />
            <div className="container">
                <h1>Privacy Policy</h1>
                <p><strong>Effective Date: 2025-03-20</strong></p>
                <p>It is Agile Katas's policy to respect your privacy regarding any information we may collect while operating our website. This Privacy Policy applies to <a href="https://agilekatas.co.uk"> agilekatas.co.uk</a> (hereinafter, "us", "we", or "agilekatas.co.uk"). We respect your privacy and are committed to protecting personally identifiable information you may provide us through the Website. We have adopted this privacy policy ("Privacy Policy") to explain what information may be collected on our Website, how we use this information, and under what circumstances we may disclose the information to third parties. This Privacy Policy applies only to information we collect through the Website and does not apply to our collection of information from other sources.</p>
                <p>This Privacy Policy sets forth the general rules and policies governing your use of our Website. Depending on your activities when visiting our Website, you may be required to agree to additional terms of service.</p>
                <h2 id="tableofcontents">Contents</h2>
                <p>Click below to jump to any section of this privacy policy</p>
                <ol>
                    <li><a href="#websitevisitors"><strong>1. Website Visitors</strong></a>
                    </li>
                    <li><a href="#Security"><strong>2. Security</strong></a>
                    </li>
                    <li><a href="#Changes"><strong>3. Privacy Policy Changes</strong></a>
                    </li>
                </ol>

                <h2 id="websitevisitors">1. Website Visitors</h2>
                <p>
                    Like most website operators, Agile Katas collects non-personally-identifying information of the sort that web browsers and servers typically make available, such as the browser type, language preference, referring site, and the date and time of each visitor request. Agile Katas's purpose in collecting non-personally identifying information is to better understand how Agile Katas's visitors use its website. From time to time, Agile Katas may release non-personally-identifying information in the aggregate, e.g., by publishing a report on trends in the usage of its website.
                </p>
                <p>
                    Agile Katas also collects potentially personally-identifying information like Internet Protocol (IP) addresses. Agile Katas only discloses IP addresses under the same circumstances that it uses and discloses personally-identifying information as described below.
                </p>
                <p>
                    <a href="#tableofcontents">Back to table of contents</a>
                </p>

                <h2 id="Security">2. Security</h2>
                <p>
                    The security of your Personal Information is important to us, but remember that no method of transmission over the Internet, or method of electronic storage is 100% secure. While we strive to use commercially acceptable means to protect your Personal Information, we cannot guarantee its absolute security.
                </p>
                <p>
                    <a href="#tableofcontents">Back to table of contents</a>
                </p>

                <h2 id="Changes">3. Privacy Policy Changes</h2>
                <p>
                    Although most changes are likely to be minor, Agile Katas may change its Privacy Policy from time to time, and in Agile Katas's sole discretion. Agile Katas encourages visitors to frequently check this page for any changes to its Privacy Policy. Your continued use of this site after any change in this Privacy Policy will constitute your acceptance of such change.
                </p>
                <p>
                    <a href="#tableofcontents">Back to table of contents</a>
                </p>
            </div>
            <div className="row about" />
        </div>
    );
}

export default Privacy;
