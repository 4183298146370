import { Component } from "react"
import Navigation from "../Components/Navigation";

class RockPaperScissorsKata extends Component {
    render() {
        return (
            <div>
                <Navigation path="katas" />
                <div className="container">
                    <h1>Rock Paper Scissors Kata</h1>
                    <div className="description">
                        <img src="/img/katas/kata_rockpaperscissors.png" alt="Rock Paper Scissors Kata" align="left" />
                        <p>
                            Rock Paper Scissors is a game involving two players making pre-defined hand gestures at each other. The gesture that each player uses is played against the other, with a winner being decided based on the rules being used.
                        </p>
                        <p>
                            The three gestures used in base Rock Paper Scissors are... well... rock, paper, and scissors. The way these are scored is as such: Rock beats Scissors, Scissors beats Paper, Paper beats Rock. It gets a lot more complicated when you introduce new gestures, but let's keep it simple for now.
                        </p>
                        <p>
                            As always, we want you to create a backend for the game that we can use to hook up to our many game clients we're going to be creating. Once again, feel free to use any front-end to test your program.
                        </p>
                        <p>
                            You can read more about <a href="https://en.wikipedia.org/wiki/Rock_paper_scissors" target="_blank" rel="noopener noreferrer">Rock Paper Scissors</a> on Wikipedia.
                        </p>
                    </div>
                    <hr />
                    <div className="features">
                        <div className="feature">
                            <h2>Feature 1 - Implementing the Basic Rules</h2>
                            <p>
                                We're definitely going to need a way to decide who has won and who has lost, or whether the round has ended in a draw. Using the rules provided, give us an engine for deciding this based on the player's moves.
                            </p>
                            <div className="row"><div className="card">
                                <h3>Rock Beats Scissors</h3>
                                <div className="card-contents">
                                    <p>
                                        As a player<br />
                                        I want rock to beat scissors<br />
                                        So that I can play with rules I'm familiar with
                                    </p>
                                    <ul>
                                        <li>
                                            Given I have chosen rock<br />
                                            When the opponent chooses scissors<br />
                                            Then I should win
                                        </li>
                                        <li>
                                            Given I have chosen scissors<br />
                                            When the opponent chooses rock<br />
                                            Then they should win
                                        </li>
                                    </ul>
                                </div>
                            </div>
                                <div className="card">
                                    <h3>Scissors Beats Paper</h3>
                                    <div className="card-contents">
                                        <p>
                                            As a player<br />
                                            I want scissors to beat paper<br />
                                            So that I can play with rules I'm familiar with
                                        </p>
                                        <ul>
                                            <li>
                                                Given I have chosen scissors<br />
                                                When the opponent chooses paper<br />
                                                Then I should win
                                            </li>
                                            <li>
                                                Given I have chosen paper<br />
                                                When the opponent chooses scissors<br />
                                                Then they should win
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                            <div className="row">
                                <div className="card">
                                    <h3>Paper Beats Rock</h3>
                                    <div className="card-contents">
                                        <p>
                                            As a player<br />
                                            I want paper to beat rock<br />
                                            So that I can play with rules I'm familiar with
                                        </p>
                                        <ul>
                                            <li>
                                                Given I have chosen paper<br />
                                                When the opponent chooses rock<br />
                                                Then I should win
                                            </li>
                                            <li>
                                                Given I have chosen rock<br />
                                                When the opponent chooses paper<br />
                                                Then they should win
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                                <div className="card">
                                    <h3>Same Moves Result in Draw</h3>
                                    <div className="card-contents">
                                        <p>
                                            As a player<br />
                                            I want the same moves to draw<br />
                                            So that I can play with rules I'm familiar with
                                        </p>
                                        <ul>
                                            <li>
                                                Given I have chosen rock<br />
                                                When the opponent chooses rock<br />
                                                Then it should be a draw
                                            </li>
                                            <li>
                                                Given I have chosen scissors<br />
                                                When the opponent chooses scissors<br />
                                                Then it should be a draw
                                            </li>
                                            <li>
                                                Given I have chosen paper<br />
                                                When the opponent chooses paper<br />
                                                Then it should be a draw
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="row katas" />
            </div>
        );
    }
}

export default RockPaperScissorsKata;
