import { Link } from "react-router-dom";
import Navigation from "../Components/Navigation";

function Home() {
    return (
        <div>
            <Navigation path="home" />
            <div className="container">
                <h1>Agile Katas</h1>
                <div>
                    <Link to="katas" className="area" id="katas">
                        <h2>Katas</h2>
                        <p>
                            Take a look at the wide range of katas that we have to practice at Agile Katas. From classic example such as FizzBuzz, Roman Numerals and the Berlin Clock, to more modern and in-depth exercises such as Rock Paper Scissors, Snakes and Ladders and Tamagotchi games- there's something for anyone at any skill level to tackle.
                        </p>
                    </Link>
                    <Link to="learn" className="area" id="learn">
                        <h2>Learn</h2>
                        <p>
                            Learn new technical skills using the katas at Agile Katas! We have a range of courses for different programming languages, technical skills and design patterns taught using the katas right here at Agile Katas.

                        </p>
                    </Link>
                    <Link to="about" className="area" id="about">
                        <h2>About</h2>
                        <p>
                            Don't know what a Kata is? Want to learn more about the things we are trying to achieve at Agile Katas? Head on over to our About section to find out more.
                        </p>
                    </Link>
                    <Link to="contact" className="area" id="contact">
                        <h2>Contact</h2>
                        <p>
                            We're open to feedback at Agile Katas and have dedicated email addresses for all sorts of enquiries. Got a great idea for a kata? Something annoying you about the site? Come on over and get in touch!
                        </p>
                    </Link>
                </div>
                <hr />
            </div>
            <div className="row home" />
        </div>
    );
}

export default Home;
